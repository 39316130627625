<template>
  <section
    v-if="myApplyList"
    class="tab-contents-wrap">
    <!-- 공모 지원 내역 (없는 경우) -->
    <div
      v-if="filteredApplicationList.length === 0"
      class="no-list-wrap">
      <div class="no-list">
          <h6 data-aos="flip-left">공모 지원 내역이 없습니다.</h6>
      </div>
    </div>
    <!-- // 공모 지원 내역 (없는 경우) -->

    <!-- 공모 지원 내역 (있는 경우) -->
    <div
      v-else
      class="board-list-wrap">
      <div class="board-top-area">
        <!-- 전체 건수 -->
        <div class="total-count">
          {{ $t('content.common.txt.txt03') }}
          <em class="space">{{ myApplyList.length }}</em>{{ $t('content.common.txt.txt04') }}
        </div>
      </div>

      <ul class="board-list">
        <li
          v-for="(application, index) in filteredApplicationList"
          :key="application.accNo"
          :data-aos="index % 2 === 0 ? 'fade-right' : 'fade-left'"
        >
          <div class="list-item">
            <dl class="info">
              <dt>{{ application.competitionTitle }}</dt>
              <dd>
                <ul class="info-detail">
                  <li>
                    <!-- 지원일 -->
                    <span>{{ $t('content.users.ServiceInfo.txt12') }}</span>
                    <time class="v-bar">
                      {{ [application.insertDate, 'YYYY.MM.DD HH:mm'] | moment('YYYY.MM.DD HH:mm') }}
                    </time>
                  </li>
                  <li class="space">
                    <!-- 상태 -->
                    <span>{{ $t('content.users.ServiceInfo.txt13') }}</span>
                    <span class="v-bar">
                      <!-- 합격 -->
                      <template v-if="application.isPass === 2">
                        <em class="status">
                          {{ $t('commonCode.AC292') }}
                          {{ $t('content.users.ServiceInfo.txt16') }}
                        </em>
                      </template>
                      <!-- 불합격 -->
                      <template v-else-if="application.isPass === 1">
                        <em class="status">{{ $t('commonCode.AC293') }}</em>
                      </template>
                      <!-- 임시저장 -->
                      <template v-else-if="application.isState === 0">
                        <em class="status">{{ $t('commonCode.AC299') }}</em>
                      </template>
                      <!-- 지원완료 -->
                      <template v-else-if="application.isState === 1">
                        <em class="status completed">{{ $t('commonCode.AC290') }}</em>
                      </template>
                      <!-- 지원취소 -->
                      <template v-else-if="application.isState === 2">
                        <em class="status cancel">
                          {{ $t('commonCode.AC291') }}
                          ({{ [application.cancelDate, 'YYYY.MM.DD HH:mm'] | moment('YYYY.MM.DD HH:mm') }})
                        </em>
                      </template>
                    </span>
                  </li>
                </ul>
              </dd>
            </dl>

            <div class="right-area">
              <!-- 공모완료 (합격, 불합격) -->
              <template v-if="application.isPass === 1 || application.isPass === 0">
                <span>{{ $t('content.users.ServiceInfo.txt18') }}</span>
              </template>
              <!-- 지원완료 -->
              <template v-else-if="application.isState === 1">
                <!-- 지원서 보기 -->
                <BaseButton
                  size="small"
                  @click="viewAccApplicationDetail(application.competitionId, application.id)"
                >{{ $t('content.common.button.btn19') }}
                </BaseButton>
                <!-- 지원서 취소 -->
                <BaseButton
                  type="line"
                  size="small"
                  @click="cancelAccMyApply(application.id)"
                >{{ $t('content.users.ServiceInfo.txt15') }}
                </BaseButton>
              </template>

              <!-- 임시저장 -->
              <template v-else-if="application.isState === 0">
                <!-- 지원서 보기 -->
                <BaseButton
                  size="small"
                  @click="goAccApply(application.competitionId)"
                >{{ $t('content.common.button.btn19') }}
                </BaseButton>
              </template>
            </div>
          </div>
        </li>
      </ul>

      <!-- 더 보기 버튼 -->
      <div
        v-if="listRows < myApplyList.length"
        class="btn-wrap">
        <MoreButton
          @click="moreAccApplicationList"
        />
      </div>
    </div>
    <!-- // 공모 지원 내역 (있는 경우) -->

    <!-- Back 버튼 -->
    <div
      class="btn-wrap">
      <BackButton
        @click="$emit('back')"
      />
    </div>
  </section>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseButton from '@/components/base/BaseButton.vue';
import MoreButton from '@/components/base/MoreButton.vue';
import BackButton from '@/components/base/BackButton.vue';
import ApplyStatus from '../enum/ApplyStatus';
import ProgramsApplicationService from '../service/ProgramsApplicationService';

const ROWS_PAGING_COUNT = 10;

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 내역 (탭)
 */
export default {
  name: 'ProgramsMyApplyList',
  mixins: [
    mixinHelperUtils,
  ],

  components: {
    BaseButton,
    MoreButton,
    BackButton,
  },

  data() {
    return {
      /**
       * Acc지원 내역
       * @type {import('../dto/AccMyApplyDto').default[]}
       */
      myApplyList: undefined,

      /**
       * 지원 상태
       */
      ApplyStatus: Object.freeze(ApplyStatus),

      userInfo: null,
      accPgmMngNoString: null
    };
  },
  computed: {
    /**
     * 표시 건수
     * @returns {number}
     */
    listRows() {
      return this.$route.query.rows ? +this.$route.query.rows : ROWS_PAGING_COUNT;
    },
    /**
     * 필터링된 Acc지원 내역
     * @returns {import('../dto/AccMyApplyDto').default[]}
     */
    filteredApplicationList() {
      return this.myApplyList ? this.myApplyList.slice(0, this.listRows) : undefined;
    },
  },

  async created() {
    this.accPgmMngNoString = this.$route.params.accPgmMngNoString
    this.userInfo = this.$store.getters.getUserInfo();
    this.programsApplicationService = new ProgramsApplicationService(this.portalApiClient);

    this.getAccMyApplyList();
  },

  methods: {
    /**
     * Acc지원 내역 조회
     */
    async getAccMyApplyList() {
      this.myApplyList = await this.programsApplicationService.getAccMyApplyList(this.accPgmMngNoString, this.userInfo.id);
    },

    /**
     * Acc지원 내역 더보기
     */
    moreAccApplicationList() {
      this.$router.replace({
        query: {
          rows: this.listRows + ROWS_PAGING_COUNT,
        },
        hash: 'last-row', // 의미없는 hash값 추가 : Vue Router의 scrollBehavior에 의해 최상단으로 가지않도록
      });
    },

    /**
     * Acc지원서 보기 (지원완료)
     * @param {number} accPgmMngNo ACC지원프로그램 관리번호 (공모전 고유번호)
     * @param {number} accNo 지원 고유번호
     */
    viewAccApplicationDetail(accPgmMngNo, accNo) {
      this.$router.push({
        name: 'ProgramsMyApplyDetail',
        params: {
          anotherAccPgmMngNoString: `${accPgmMngNo}`,
          anotherAccNoString: `${accNo}`,
        },
      });
    },

    /**
     * Acc지원서 보기 (임시저장)
     * @param {number} accPgmMngNo ACC지원프로그램 관리번호 (공모전 고유번호)
     */
    goAccApply(accPgmMngNo) {
      this.$router.push({
        name: 'ProgramsApplyForm',
        params: {
          accPgmMngNoString: `${accPgmMngNo}`,
        },
      });
    },

    /**
     * Acc지원 취소
     * @param {number} accNo 지원 고유번호
     * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120194 Front.Acc액셀러레이팅메인 정보취소}
     */
    cancelAccMyApply(accNo) {
      this.showConfirm(
        this.$t('confirmMessage.canceledProgram'), () => {

          const res = this.programsApplicationService.cancelAccMyApply(accNo, this.userInfo.id)
            .then(({ data, status }) => {
              if (status === 200) {
                this.showAlert(
                  this.$t('alertMessage.canceled'),
                  () => {
                    this.getAccMyApplyList();
                  },
                );
              } else {
                this.showAlert(`오류가 발생했습니다.<br>(오류코드: ${status} )`);
              }
            });
        },
      );
    },
  },
};
</script>
